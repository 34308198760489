import React, {Component} from 'react';
import ScrollToTop from 'react-scroll-up';
import {FiCheck, FiChevronUp} from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import {slideSlick} from "../page-demo/script";
import Contact from "../elements/contact/ContactTwo";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Yoga, Pilates et bien-être',
        title: 'Chris Happy Pink',
        description: '',
        buttonText: 'Download My CV',
        buttonLink: ''
    }
];

const SlideListCourses = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--29',
        category: '',
        title: 'Yoga',
        description: 'Happy Pink Yoga®, Yoga doux, Yoga de la femme, Yoga Dance',
        buttonText: 'Détails',
        buttonLink: '/yoga'
    },
    // {
    //     textPosition: 'text-left',
    //     bgImage: 'bg_image--30',
    //     category: '',
    //     title: 'Renforcement',
    //     description: 'Senior Mouv, Total Body',
    //     buttonText: 'Détails',
    //     buttonLink: '/renforcement'
    // },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Stretching',
        description: '',
        buttonText: 'Détails',
        buttonLink: '/stretching'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--32',
        category: '',
        title: 'Pilates',
        description: '',
        buttonText: 'Détails',
        buttonLink: '/pilates'
    }
    // {
    //     textPosition: 'text-left',
    //     bgImage: 'bg_image--33',
    //     category: '',
    //     title: 'Danse',
    //     description: 'Modern Jazz, Cabaret',
    //     buttonText: 'Détails',
    //     buttonLink: '/danse'
    // },
];

class PortfolioLanding extends Component {
    title = 'About Me';
    description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
    postList = BlogContent.slice(0 , 3);
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
        this.selectChange = this.selectChange.bind(this);
    }
    selectChange(index) {
        console.log('Dans père, index = ' + index);
        this.setState({
            tab: index
        });
    }
    render() {
        const noBorderStyle = {
            borderBottom: 'initial',
            marginBottom: 'initial'
        };

        const noMaxWidth = {
            maxWidth: 'initial'
        };

        const lessPaddingStyle = {
            paddingLeft: '20px',
            paddingRight: '20px'
        };

        const centerStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };

        const heightStyle = {
            height: '400px'
        };

        const bioStyle = {
            fontSize: '15px'
        };

        const titleStyle = {
            fontSize: '36px',
            color: '#F19DBE'
        };

        const bgYellowStyle = {
            backgroundColor: 'lightyellow'
        };

        const bgBlueStyle = {
            backgroundColor: 'lightpink'
        };

        const bgVioletStyle = {
            backgroundColor: 'violet'
        };

        const bgGreenStyle = {
            backgroundColor: 'hotpink'
        };

        const bgButton = {
            borderRadius: '500px',
            borderColor: '#757575',
            marginLeft: '15px'
        };

        return (
            <div>
                <Helmet pageTitle="Chris Happy Pink" />
                <HeaderThree homeLink="/" logo="chris-happy-pink" color="color-black"/>
                {/* Start Slider Area   */}
                <div id="home" className="fix">
                    <div className="slider-wrapper">
                        {/* Start Single Slide */}
                        {SlideList.map((value , index) => (
                            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                                <div className="container" style={noMaxWidth}>
                                    <div className="row" style={heightStyle}>
                                        <div className="col-lg-8" style={centerStyle}>
                                            {/*<div className={`inner ${value.textPosition}`}>*/}
                                            <div>
                                                {/*<br /><br />*/}
                                                {/*{value.category ? <span className="font-700" style={titleStyle}>{value.category}</span> : ''}*/}
                                                <span className="font-700" style={titleStyle}>Yoga<br />Pilates<br />Stretching</span>
                                                {/*{value.title ? <h1 className="title theme-gradient" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}*/}
                                                {/*{value.description ? <p className="description">{value.description}</p> : ''}*/}
                                                {/*{value.buttonText ? <div className="slide-btn mt--20"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}*/}
                                                {/*<br /><br /><br /><br /><br />*/}
                                            </div>
                                        </div>
                                        <div className="col-lg-4" style={centerStyle}>
                                            <div className="logo_about">
                                                <img src="/assets/images/logo/logo-chp.png" alt="About Images"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div id="about" className="fix">
                    {/*<div className="about-area ptb--120 bg_color--5">*/}
                    <div className="about-area ptb--120">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-7.jpg" alt="About Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">Faisons connaissance</h2>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Petite belge débarquée à Paris il y a vingt ans. J'étais enseignante et mon rêve était de danser !
                                                    J'ai donc dansé à Disneyland Paris sur les parades pendant trois ans. Je me suis alors rendu compte
                                                    que l'enseignement me manquait et que ma vraie passion était de transmettre !
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Evidemment, on transmet uniquement ce que l'on aime. Pour ma part au début, c'était la danse jazz.
                                                    Après une formation en école de danse (jazz, classique) et l'aquisition d'un BPJEPS AGFF (professeur de fitness),
                                                    mon chemin de vie s'est affiné. En fait, ce que j'aime par dessus tout, c'est prendre soin des personnes qui m'entourent !
                                                    C'est pourquoi je me suis formée en Pilates, stretching et autres méthodes de renforcements dites "douces".
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    En 2016, j'ai découvert le yoga, d'abord le yoga Vinyasa, un yoga très sportif.
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Malheureusement en 2018, la vie a décidé de me challenger. J'ai eu un cancer du sein.
                                                    Période de ma vie de sportive très compliquée et pourtant riche en découverte.
                                                    Je me suis intéressée à des yogas plus doux, adaptés à mon nouveau corps : Hatha, Nidra...
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Je suis maintenant en rémission depuis mars 2020 et professeur de yoga certifiée depuis mars 2021.
                                                    Forte de toutes ces expériences, je suis maintenant prête à prendre soin de vous, tout en respectant
                                                    votre corps et son histoire !
                                                </p>
                                                <p className="description mt_dec--20" style={bioStyle}>
                                                    Vive le sport, vive la vie !
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Courses Area   */}
                <div id="courses" className="slider-wrapper" >
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideListCourses.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {/*{value.buttonText ? <div className="slide-btn"><Link className="btn-default btn-border btn-opacity" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}*/}
                                                    {/*<div className="slide-btn"><Link to="yoga">{value.buttonText}</Link></div>*/}
                                                    {value.buttonText ? <div className="slide-btn"><a className="btn-default btn-border btn-opacity" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Pricing Tbale Area  */}
                <div id="fees" className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Tarifs</h2>
                                    {/*<p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Zoom</h4>
                                            <div className="pricing">
                                                <span className="price">12 &euro;</span>
                                                <span className="subtitle">cours d'une heure</span>
                                            </div>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price">90 &euro;</span>
                                                <span className="subtitle">la carte de 10 cours (soit 9 &euro; par cours)</span>
                                            </div>
                                        </div>
                                        <div className="pricing-header" style={noBorderStyle}>
                                            <div className="pricing">
                                                <span className="price"><span style={{textDecoration: 'line-through'}}>50 &euro;</span> 40 &euro;</span>
                                                <span className="subtitle">la carte de 5 cours (soit 10 &euro; par cours)</span>
                                            </div>
                                        </div>
                                        {/*<div className="pricing-footer">*/}
                                        {/*    <a className="rn-btn" href="#pricing">Purchase Now</a>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Collectifs (présentiel)</h4>
                                            <div className="pricing">
                                                <span className="price">16 &euro;</span>
                                                <span className="subtitle">cours d'une heure</span>
                                            </div>
                                        </div>
                                        <div className="pricing-header">
                                            <div className="pricing">
                                                <span className="price">110 &euro;</span>
                                                <span className="subtitle">la carte de 10 cours (soit 11 &euro; par cours)</span>
                                            </div>
                                        </div>
                                        <div className="pricing-header" style={noBorderStyle}>
                                            <div className="pricing">
                                                <span className="price">65 &euro;</span>
                                                <span className="subtitle">la carte de 5 cours (soit 13 &euro; par cours)</span>
                                            </div>
                                        </div>
                                        {/* <div className="pricing-footer">*/}
                                        {/*    <a className="rn-btn" href="#pricing">Purchase Now</a>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner" style={lessPaddingStyle}>
                                        {/*<div className="pricing-header">*/}
                                        {/*    <h4 className="title">Associations</h4>*/}
                                        {/*    Infos directement auprès des secrétaires :*/}
                                        {/*    <ul className="list-style--1 mt--10">*/}
                                        {/*        <li><FiCheck /> Moussy Fitness : 06 51 35 40 90</li>*/}
                                        {/*        <li><FiCheck /> Centre Culturel d'Othis : 01 64 02 74 36</li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                        <div className="pricing-body">
                                            <h4 className="title">Individuels et entreprises</h4>
                                            Contactez-moi pour discuter ensemble de vos objectifs et/ou de votre projet !
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}

                {/* Start Planning Area */}
                <div id="planning" className="rn-pricing-table-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Planning</h2>
                                    {/*<p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Lundi</th>
                                            {/*<th>Mardi</th>*/}
                                            <th>Mercredi</th>
                                            <th>Jeudi</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>&nbsp;</td>
                                            {/*<td>&nbsp;</td>*/}
                                            <td bgcolor="hotpink">12h15 &agrave; 13h15 : Yoga doux<br />(adapt&eacute; &eacute;galement cancer du sein)</td>
                                            {/*<td bgcocolor="lightpink">10h &agrave; 11h : Senior Mouv'</td>*/}
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            {/*<td>&nbsp;</td>*/}
                                            <td>&nbsp;</td>
                                            {/*<td bgcolor="lightpink">11h &agrave; 12h : Pilates</td>*/}
                                            <td bgcolor="hotpink">15h &agrave; 16h : Pilates and Stretch'</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*    <td>&nbsp;</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td bgcolor="lightyellow">19h30 &agrave; 20h30 : Pilates and Stretch'</td>
                                            {/*<td bgcolor="lightpink">20h00 &agrave; 21h00 : Danse Modern Jazz<br />(ados et adultes)</td>*/}
                                            {/*<td>&nbsp;</td>*/}
                                            <td bgcolor="lightyellow">19h30 &agrave; 20h30 : Yoga de la femme</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="lightyellow">20h30 &agrave; 21h30 : Happy Pink Yoga®</td>
                                            {/*<td bgcolor="lightpink">21h00 &agrave; 22h00 : Pilates</td>*/}
                                            {/*<td>&nbsp;</td>*/}
                                            {/*<td bgcolor="violet">20h00 &agrave; 21h00 : Yoga Vinyasa</td>*/}
                                            <td>&nbsp;</td>
                                            {/*<td bgcolor="lightyellow">20h00 &agrave; 21h00 : Yoga Vinyasa</td>*/}
                                            <td>&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="list-style--1 mt--10">
                                    <li>
                                        <FiCheck /> <span style={bgYellowStyle}>Zoom</span>
                                        <a className="btn btn-outline-danger btn-sm" style={bgButton} target="_blank" href="https://www.danceschool.fr/#/school/chrishappypink">R&eacute;server un cours</a>
                                        <a className="btn btn-outline-danger btn-sm" style={bgButton} target="_blank" href="https://www.danceschool.fr/#/school/chrishappypink">Acheter un replay</a>
                                    </li>
                                    {/*<li><FiCheck /> <span style={bgBlueStyle}>Cours association Othis 4 rue du 19 Mars 1962, 77280 Othis</span></li>*/}
                                    {/*<li><FiCheck /> <span style={bgVioletStyle}>Cours association Moussy Chemin des Vignettes, 77230 Moussy-le-Vieux</span></li>*/}
                                    <li>
                                        <FiCheck /> <span style={bgGreenStyle}>Cours collectifs Social Club 3 rue Robert Schuman, 77410 Claye-Souilly (Shopping Promenade)</span>
                                        <a className="btn btn-outline-danger btn-sm" style={bgButton} target="_blank" href="https://www.danceschool.fr/#/school/chrishappypink">R&eacute;server un cours</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}

                {/* Start COntact Area */}
                <div id="contact" className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div>
                {/*<div id="contact" className="fix">*/}
                {/*    <div className="rn-contact-area pb--120 bg_color--5">*/}
                {/*        <ContactOne />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End COntact Area */}

                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}

export default PortfolioLanding;
